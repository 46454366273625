@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
.wraper {
    overflow: hidden;
    height: auto;
}
body {
    margin: 0;
    color: #f2f2f2;
    font-size: 16px;
    font-family: "Outfit";
    background: #F5F7FB;
}
dl,
ol,
ul {
  margin: 0;
  padding: 0;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  color: #25213b;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus,
button:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000 !important;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-top: 0;
}
.container-width {
    width: 100%;
    max-width: 1590px;
    /* padding: 0px 66px; */
    margin: 0px auto;
}
.button-holder.btn {
    background: linear-gradient(270.16deg, #4f2c92 -26.68%, #0c0c11 59.69%, #0c0c11 88.22%, #de6ced 121.38%);
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 21px;
    text-align: center;
    color: #fff !important;
    width: 188px;
    font-weight: 400;
    -o-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -webkit-transition: all 0.3s ease-in-out;
    padding: 10px !important;
    border: 2px solid linear-gradient(270.16deg, #4f2c92 -26.68%, #0c0c11 59.69%, #0c0c11 88.22%, #de6ced 121.38%);
}
.button-holder.btn:hover {
    border-color: #0c0c11;
    color: #0c0c11 !important;
    background: #ffff;
}
.btn-yellow{
    background: #FEDB22;
    border: 2px solid #FEDB22;
    box-shadow: 0px 10px 40px rgba(254, 219, 34, 0.4);
    border-radius: 6px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: #0C0C11;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 40px;
}
.btn-yellow:hover{
    background-color: #1D9BF0;
    border-color: #1D9BF0;
    color: #fff;
    box-shadow: 0px 10px 40px rgba(29, 156, 240, 0.4);
}
.btn-yellow:disabled {
  background: #fedb22;
  border-color: #fedb22;
  color: #000;
}
.btn-white-border {
    border: 2px solid #212429;
    color: #212429;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 40px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}
.btn-white-border:hover {
    color: #1D9BF0;
    border-color: #1D9BF0;
}
.text-blue {
    color: #1D9BF0 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.back-btn-style button {
  border: none;
  font-size: 14px;
  background: transparent;
}
.colum-fix-w{
  width: 140px;
}
.container-width {
  width: 100%;
  /* padding: 0px 200px; */
  margin: 0px auto;
}
.button-holder.btn {
  background: linear-gradient(
    270.16deg,
    #4f2c92 -26.68%,
    #0c0c11 59.69%,
    #0c0c11 88.22%,
    #de6ced 121.38%
  );
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 21px;
  text-align: center;
  color: #fff !important;
  width: 188px;
  font-weight: 400;
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all 0.3s ease-in-out;
  padding: 10px !important;
  border: 2px solid
    linear-gradient(
      270.16deg,
      #4f2c92 -26.68%,
      #0c0c11 59.69%,
      #0c0c11 88.22%,
      #de6ced 121.38%
    );
}
.button-holder.btn:hover {
  border-color: #0c0c11;
  color: #0c0c11 !important;
  background: #ffff;
}
/* header */
.main-header .navbar-expand-lg .navbar-nav {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header.main-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
}
.main-header .logo {
    width: 280px;
    display: block;
}
.main-header .nav-link,
.document-sty {
  padding: 5px;
  margin-right: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #b6b6b6 !important;
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.header-logo {
    width: 200px;
}
#header {
    padding: 40px 0;
    border: none;
}
.main-header .navbar {
    padding: 0;
}

.main-header .navbar-brand {
    padding: 0;
}
#header .navbar-nav .nav-link.active,
#header .navbar-nav .nav-link:hover,
.document-sty:hover {
  color: #de6ced !important;
}
.main-header .navbar .nav-item {
    height: 50px;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    margin-right: 20px;
}
.main-header .navbar .nav-item:hover {
    color: #1D9BF0;
}
.status-bg.badge {
  font-size: 14px;
  width: 82px;
  padding: 8px;
  color: #fff;
}
/*main  baner css */
#site-banner {
    padding: 110px 0 90px;
    background-size: 60%;
    background-image: url(./assets/images/top-banner-bg.png);
    background-position: right top;
    background-repeat: no-repeat;
    position: relative;
}
#site-banner::before {
    content: '';
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    clip-path: polygon(0 0, 100% 1%, 100% 50%, 0% 100%);
}
.banner-content{
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    max-width: 700px;
}
.banner-content h1 {
    font-size: 96px;
    line-height: 90px;
    font-weight: 800;
    color: #FEDB22E5;
    text-transform: uppercase;
}
.banner-content h2 {
    font-weight: 900;
    font-size: 68px;
    line-height: 68px;
    color: #1D9BF0;
    margin-top: -10px;
    text-transform: uppercase;
}
.banner-content h4{
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: #0C0C11;
    text-transform: uppercase;
}
p{
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color:#0C0C1199;
    margin: 30px 0;
}
.link-d {
}
/* whyprotocol css */
.whyprotocol {
  background: url(assets/images/gredient-bg.png);
  background-size: cover;
  padding: 130px 0;
  z-index: 2;
  position: relative;
}
.whyprotocol .space {
  margin-top: 130px;
}
.whyprotocol .box-1 {
  background-color: #131823;
  background-clip: padding-box;
  position: relative;
  padding: 80px;
  margin: 0 auto;
  position: relative;
  border: 11px solid transparent;
  border-radius: 64px;
  background-clip: padding-box;
}
.border-gradient {
  position: relative;
  border: 11px solid transparent;
  border-radius: 64px !important;
  background-clip: padding-box;
}
.border-gradient::after {
  background: linear-gradient(
    74.8deg,
    #4f2c92 2.49%,
    #0c0c11 38.98%,
    #0c0c11 68.06%,
    #de6ced 104.02%
  ) !important;
  border-radius: 64px;
  position: absolute;
  top: -11px;
  bottom: -11px;
  left: -11px;
  right: -11px;
  content: "";
  z-index: -1;
}
.whyprotocol .box-2 {
  background-color: #131823;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 22px;
  position: relative;
  padding: 80px 285px;
  margin: 0 auto;
  border-radius: 165px;
}
.whyprotocol .box-2:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -11px;
  border-radius: inherit;
  background: linear-gradient(
    74.8deg,
    #4f2c92 2.49%,
    #0c0c11 38.98%,
    #0c0c11 68.06%,
    #de6ced 104.02%
  ) !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.whyprotocol .heading {
  font-weight: bold;
  font-size: 58px;
  line-height: 32px;
  color: #ffffff;
}
.list-items .intro {
  font-weight: normal;
  font-size: 31px;
  line-height: 39px;
  letter-spacing: -0.4px;
  color: #f2f2f2;
  margin-top: 19px;
  width: 1052px;
}
.more-detail a {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  color: #f2f2f2;
  width: 812px;
  text-overflow: ellipsis;
  margin-top: 30px;
  line-height: 50px;
}
.box-2 {
  margin-top: 130px;
}
.social-icons ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icons li a {
  font-size: 45px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #d568e7;
  color: #fff;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
}
.social-icons ul li {
  list-style-type: none;
  display: inline-block;
  margin: 0 0 0 40px;
}
.social-icons li:first-child {
  margin: 0;
}
.table-social-links li a {
  width: 35px !important;
  height: 35px !important;
  background: #1D9BF0;
  font-size: 15px !important;
  border-radius: 4px !important;
  border: 1px solid transparent;
}
.social-icons li a:hover {
  background: #fff;
  color: #d568e7;
  border-color: #d568e7;
  z-index: 500 !important;
  position: relative;
}
.table-social-links li a:hover {
  background: #fff;
  color: #1D9BF0;
  border-color: #1D9BF0;
}
.table-social-links ul li {
  margin: 0 0 0 10px !important;
}
.table-social-links li:first-child {
  margin: 0 !important;
}
.box-2 p {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 60px 0;
}
.info-icon {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  border: 2px solid #283A5CBF;
  margin-left: 7px;
}
.info-icon > svg {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.link-second {
  margin-top: 270px;
}
/* request section */
#request {
  background: #F5F7FB;
  /* padding: 50px 0px 100px; */
  padding-bottom: 50px;
  color: #0C0C11;
  min-height: 100vh;
}
.contact-us {
  padding: 50px 100px 100px;
}
.contact-us.login-pg{
  height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
#request h6 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
}
.connect {
  float: right;
  display: flex;
}
.wallet-btn {
  background: linear-gradient(
    270.16deg,
    #4f2c92 -26.68%,
    #0c0c11 59.69%,
    #0c0c11 88.22%,
    #de6ced 121.38%
  ) !important;
}
.connect-btn,
.wallet-btn {
  padding: 8px 30px;
  font-size: 20px;
  background: #1D9BF0;
  border-radius: 40px;
  border: none;
  color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.connect button{
  border-radius: 40px;
}
.twiter-color {
  color: #55acee;
}

.active-tab-section .nav-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #283A5CBF;
  margin-right: 5px;
  border: none;
  padding: 11px 11px 11px 0px;
  border-bottom: 2px solid transparent;
}
.active-tab-section .nav-tabs {
  border-color: #c6c2de;
margin-top: 30px;
}
.active-tab-section .nav-link:hover,
.active-tab-section .nav-tabs .nav-link.active {
  color: #0C0C11;
  background-color: transparent;
  border-bottom: 2px solid #0C0C11;
}
.card {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
}
.tabone-content .card-header {
  background: #ffffff;
  border-bottom: 1px solid #c6c2de;
  padding: 24px;
}
.boder-btn.sigunp-btn {
  background: transparent;
  border-color: #55acee;
}
.boder-btn.sigunp-btn:hover {
  background: #55acee;
}
.sigunp-btn {
  border-radius: 32px;
  background: #55acee;
  padding: 12px 16px;
  width: 360px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-white {
  background: transparent;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #25213b;
  padding: 10px;
}
.btn-white:hover {
  background: #1D9BF0;
  color: #fff;
}
.btn-white:hover.btn-white > svg {
  color: #fff !important;
}
.contactus-btns .btn-white {
  border-radius: 32px;
  background: #fff;
  padding: 12px 16px;
  width: 360px;
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactus-btns .btn-white:hover {
  background: #000;
  color: #fff;
}
/*  */
[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
.agreement-detail p {
  color: #c6c2de;
  font-size: 14px;
  font-weight: 500;
  width: 387px;
  margin-top: 10px;
}
.agreement-detail a {
  color: #55acee !important;
  text-decoration: none;
}
[type="file"] + label {
  background: #f15d22;
  border: none;
  border-radius: 7px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0.7rem 37px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
[type="file"] + label:hover {
  background-color: darken(#f15d22, 10%);
}
[type="file"] + label.btn-2 {
  background-color: #fedb22;
  border-radius: 8px;
  overflow: hidden;
  color: #000;
}

[type="file"] + label.btn-2:hover {
  background-color: #1D9BF0;
  color: #fff;
}
[type="file"] + label.btn-2::before {
  right: 75%;
}
/*  */
.btn-purple,
.btn-purple:focus {
  background: #1D9BF0;
  border-radius: 6px;
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Inter";
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid transparent;
  padding: 0.7rem 37px;
}
.filters-action .btn-white > svg {
  color: #283A5CBF !important;
  margin-right: 10px;
}
.custom-pagination button {
  border: none;
  background: transparent;
}
.learn-more {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #283A5CBF;
}
.learn-more svg,
.learn-more2 svg {
  margin-left: 10px;
}
.text-prpl p {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1D9BF0;
}
.text-prpl span {
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;
  line-height: 22px;
  color: #283A5CBF;
  margin-left: 8px;
}
.tab-one-table.table thead > tr > th {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0C0C11;
  background: #F5F7FB;
  padding: 15px 32px;
  border-bottom: 1px solid #c6c2de;
  white-space: nowrap;
}
.table > :not(:first-child) {
  border-top: none;
}
.custom-dashboard .tab-one-table.table tbody > tr > td,
.custom-dashboard .request-table.tab-one-table.table thead > tr > th {
  text-align: center !important;
}
.custom-dashboard .tab-one-table.table thead {
  border-top: 1px solid #d9d5ec;
}
.tab-one-table.table tbody > tr > td {
  font-weight: 400;
  font-size: 15px;
  color: #283A5CBF;
  padding: 15px 32px;
  border-bottom: 1px solid #d9d5ec;
  vertical-align: middle;
  line-height: 19px;
}
.request-table.tab-one-table.table thead > tr > th {
  padding: 15px 15px;
}
.request-table.tab-one-table.table tbody > tr > td {
  padding: 15px 15px;
}
.active-page .card-body,
.your-request-page .card-body {
  padding: 0;
}
.active-tab-section .card {
  border-radius: 8px;
}
.active-tab-section .card-footer {
  background-color: #F5F7FB;
  padding: 10px 32px;
  border: none;
}
.active-tab-section .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - -3px) calc(0.25rem - -4px);
}
.next-page-info a {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #283A5CBF;
  margin-top: 10px;
}
.shape-up {
  width: 24px;
  height: 24px;
  border: 2px solid #283A5CBF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0 auto;
}
/* make-request-tab css */
.make-request-tab {
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 30px 50px;
}
.make-request-tab h2 {
  font-weight: bold;
  font-size: 45px;
  line-height: 55px;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.make-request-tab .learn-more2 {
  font-weight: bold;
  font-size: 18px;
  color: #1d9bf0;
  line-height: 30px;
}
.make-request-tab .learn-more2:hover {
  color: #FEDB22;
}
.how-does-work {
  margin-top: 20px;
}
.how-does-work h3,
.how-does-work li {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0C0C11;
}
.how-does-work ol {
  margin-left: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.how-does-work li {
margin-bottom: 5px;
}
.make-request-btn {
  background: linear-gradient(
      270.16deg,
      #4f2c92 -26.68%,
      #1D9BF0 59.69%,
      #1D9BF0 88.22%,
      #a091f4 121.38%
    ),
    linear-gradient(
      270.16deg,
      #4f2c92 -26.68%,
      #0c0c11 59.69%,
      #0c0c11 88.22%,
      #de6ced 121.38%
    );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  width: 120px;
  height: 50px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.social-buttons {
  margin: 50px 0;
}
.make-question input:focus {
  border-color: #fff;
}
::placeholder {
  color: #fff;
}
.figure-heading h1 {
  font-weight: bold;
  font-size: 60px;
  line-height: 73px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
}
.figure-heading {
  margin-top: 40px;
}
.make-request-btn2:hover,
.make-request-btn:hover,
.connect-btn:hover,
.btn-purple:hover {
  /* background: #fff !important;
  color: #000;
  border-color: #1D9BF0;
  border: 1px solid #1D9BF0; */
}
.make-request-btn2 {
  /* background: #FEDB22;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 7px;
  /* color: #000; */
  width: 300px;
  max-width: 100%;
  height: 55px;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  /* border: 1px solid #FEDB22;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; */
}
/* staking tab */
.staking-tab h1 {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #0C0C11;
  /* width: 550px; */
}
.staking-tab h3 {
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  color: #0C0C11;
}
.staking-tab h2 {
  font-weight: bold;
  font-size: 63px;
  line-height: 76px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0C0C11;
}
.stake-content {
  display: flex;
  justify-content: end;
}
.stake-details ol {
  margin: 70px 0;
  margin-left: 40px;
  display: flex;
}
.stake-details ol li:last-child {
  margin-right: 0;
}
.stake-details ol li {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #0C0C11;
  width: 205px;
  margin-right: 150px;
}
.stake-details ol li p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0C0C11;
  margin-top: 10px;
  margin-left: -20px;
}
.note p {
  margin-top: 20px;
  font-size: 12px !important;
  line-height: 20px !important;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.claim-prpl {
  width: 100%;
}
.usdc-field.balance-input-field {
  width: 77%;
}
.balance-button {
  width: 100%;
}
.balance-input-field {
  position: relative;
  border: 1px solid transparent;
  border-radius: 7px !important;
  background-clip: padding-box;
  color: #212529;
  z-index: 1;
  height: 55px;
}
.balance-input-field .form-control,
.custom-design-field {
  background: #131823;
  border-radius: 7px;
  border: none;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-design-field {
  background: #131823;
  border-radius: 7px;
  border: none;
  color: rgba(255, 255, 255, 0.28);
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  width: 100%;
  padding: 10px;
}
.custom-design-field > p {
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #ffffff;
  word-break: break-all;
  margin: 0;
}
.balance-input-field::after {
  background: linear-gradient(
    74.8deg,
    #4f2c92 2.49%,
    #0c0c11 38.98%,
    #0c0c11 68.06%,
    #de6ced 104.02%
  ) !important;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
}
.balance-button .btn {
  /* background: linear-gradient(
      270.16deg,
      #4f2c92 -26.68%,
      #1D9BF0 59.69%,
      #1D9BF0 88.22%,
      #a091f4 121.38%
    ),
    linear-gradient(
      270.16deg,
      #4f2c92 -26.68%,
      #0c0c11 59.69%,
      #0c0c11 88.22%,
      #de6ced 121.38%
    );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  width: 100%; */
  margin-top: 20px;
  /* font-weight: bold;
  font-size: 25px;
  line-height: 30px; */
}
.stake-common-btn .balance-button .btn {
  font-size: 17px;
  margin-top: 20px;
  width: 100%;
}
.stake-common-btn .balance-button:first-child {
  margin-right: 20px;
}
.max-button .btn {
  font-weight: normal;
  width: 96px;
  height: 51px;
  border-radius: 7px;
}
.balance-section p {
  font-weight: normal;
  font-size: 15px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.max-button .btn {
  margin-top: 0;
}
/* request section end */
.btn-white:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1);
}
/* filter drop down style */
.filters-action .dropdown-menu {
  background: #fff;
  position: absolute;
  border: none;
  top: 70px;
  left: 23px;
  width: 300px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  padding: 0;
  transform: scaleX(0);
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.filters-action .dropdown-menu label {
  margin: 0;
  position: relative;
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #25213b;
}
.filters-action .dropdown-menu p {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #283A5CBF;
  padding: 0.5rem 1rem;
}
/* filter drop down style radio button */
.option-values [type="radio"]:checked,
.option-values [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.option-values [type="radio"]:checked + label,
.option-values [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 0;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 90px;
}
.option-values [type="radio"]:checked + label:before,
.option-values [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  /* left: 0; */
  top: 0;
  width: 18px;
  height: 18px;
  border: 1.5px solid #8b83ba;
  border-radius: 100%;
  background: #fff;
  right: -171px;
}
.option-values [type="radio"]:checked + label:after,
.option-values [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0C0C11;
  position: absolute;
  top: 3px;
  /* left: 3px; */
  border-radius: 100%;
  transition: all 0.2s ease;
  right: -168px;
}
.option-values [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.option-values [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.option-values {
  padding: 0.5rem 1rem;
}
.option-values:hover {
  background: #f2f0f9;
}
.rows-per-page .btn-primary {
  color: #283A5CBF;
  background-color: transparent;
  border-color: transparent;
}
.rows-per-page .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  vertical-align: middle;
}
.rows-per-page .dropdown-menu {
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 20%);
  border-radius: 6px;
}
.rows-per-page p {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #283A5CBF;
}
.angle-icon {
  color: #283A5CBF;
  font-size: 21px;
  margin-left: 65px;
  font-weight: 500;
}
.balance-section {
  /* padding-left: 100px; */
}
.learn-how {
  font-size: 18px;
  line-height: 24px;
  color: #0C0C11;
  margin-top: 5px;
  font-family: "NotoSans";
}
.style-text {
  font-style: italic;
}
.make-question {
  margin: 5px 0;
}
.modal-backdrop.show {
  opacity: 2;
}
.or-text {
  position: relative;
}
.or-text p {
  text-align: center;
  margin-left: 28%;
  padding: 20px 0;
}
.or-text :before {
  content: "";
  width: 2px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 2px #cc366f;
  margin-left: -1px;
}
.make-question p,
.or-text p,
.totall-bill-model p,
.totall-bill-model a {
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #0C0C11 !important;
  margin-right: 20px;
}
.make-question p {
  width: 240px;
}
.t-url-fil {
  width: 80%;
  margin: 20px auto;
}
.t-url-fil .questioner-input-field.custom-w {
  width: 60%;
}
.make-question .t-url-fil p {
  width: 100%;
}
.totall-bill-model .questioner-input-field .form-control {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    background: #d7d7d7ee;
    color: #000;
    box-shadow: none;
}
.questioner-input-field {
  position: relative;
  border: 1px solid transparent;
  border-radius: 7px !important;
  background-clip: padding-box;
  color: #fff;
  z-index: 1;
  width: 160px;
}

.questioner-input-field.custom-w {
  width: 81%;
}
::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
}
.questioner-input-field .form-control {
  border-radius: 7px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  background: #000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  min-height: calc(1.5em + 1rem + -2px);
  padding: 5px 10px;
  height: 45px;
}
.questioner-input-field::after {
  background: linear-gradient(
    100deg,
    #1d9bf0 2.49%,
    #1d9bf0 38.98%,
    #fedb22 68.06%,
    #fedb22 104.02%
  ) !important;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  bottom: -2px;
  left: -2px;
  right: -1px;
  content: "";
  z-index: -1;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.style-fields {
  padding-right: 30px;
}
.connect .dropdown-menu {
  background: #fff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  min-width: 230px;
  margin-top: 10px;
}
.dropdown > .connect-btn {
  width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background: #fff !important;
  color: #0C0C11;
  border-color: #1D9BF0;
  border: 1px solid #1D9BF0;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  background: #1d9bf0;
}
.dropdown-item:hover {
  background: #1d9bf0;
  color: #fff;
}
/* make request totall bill payment */
.totall-bill-model .modal-content {
  background: #fff;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 30px 35px;
}
.totall-bill-model .go-back a > svg {
  color: #fff;
  font-size: 25px;
  margin-right: 10px;
}
.totall-bill-model .modal-header {
  border: none;
  padding: 0;
}
.totall-bill-model .modal-header .btn-close {
  display: none;
}
.totall-bill-model .modal-body {
  padding: 0;
  margin-top: 30px;
}
.totall-bill-model .modal-dialog {
  max-width: 88%;
  padding: 0 120px;
}
.inner-content {
  max-width: 100%;
  margin: 0 auto;
}
.post-request-text {
  /* background: #fff; */
  padding: 10px 20px;
  margin-bottom: 20px;
}
.totall-bill-model h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000;
}
.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.heading-2 {
  font-size: 35px !important;
}
.title-2,
.heading-2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #000;
}
.common-w {
  width: 50%;
}
.post-request-text h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000;
}
.view-req {
  width: 7%;
}
.view-req > a {
  color: #1d9bf0 !important;
  font-size: 12px;
  white-space: nowrap;
}
/* progress bar */

.progress p,
.reqest-progress p {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #443882;
}
.like-progress .progress {
}
.custome-de-pr .progress {
  width: 100%;
  background-color: #e9ecef;
  margin: none;
  height: 15px;
}
.custome-de-pr {
  width: 100%;
}
.prpl-dashboard .progress {
  margin: unset;
  width: 100% !important;
}
.progress {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 1px;
  background: rgb(193 193 193 / 78%);
  height: 20px;
  width: 0;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
}
.progress-bar {
  padding: 13px !important;
  background: #1D9BF0;
  border-radius: 15px;
  align-items: center;
  position: relative;
  display: flex;
  height: 40px;
  width: 70%;
  border-radius: 20px;
}
.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.78);
  height: 14px;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reqest-progress {
  background: #1D9BF0;
  border-radius: 15px;
  align-items: center;
  position: relative;
  padding: 5px 5px;
  display: flex;
  height: 40px;
  width: 70%;
  margin: 0 auto;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0.8em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  font-size: 29px;
  background: linear-gradient(
    270.16deg,
    #4f2c92 -26.68%,
    #0c0c11 59.69%,
    #0c0c11 88.22%,
    #de6ced 121.38%
  ) !important;
  color: #ffff !important;
}
.heading-p p {
  width: 70%;
  margin: 10px auto;
}
.reqest-progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.78);
  height: 25px;
  width: 0;
}
.reward-text h2 {
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.reward-text h1 {
  font-weight: bold;
  font-size: 64px;
  line-height: 52px;
  letter-spacing: -0.4px;
  color: #0C0C11;
  margin-left: 60px;
}
.text-purple {
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 48px !important;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #0c0c11 !important;
}
.accepted-request .tab-one-table.table tbody > tr > td {
  border: none !important;
  white-space: nowrap;
}
.reqest-detail .questioner-input-field .form-control::placeholder {
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #696363;
}
.questioner-input-field .form-control::placeholder {
  color: #fff;
}
.reqest-detail .questioner-input-field .form-control {
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #fff;
}
.addres-box.questioner-input-field .form-control {
  border-radius: 30px;
  font-size: 17px;
  text-align: center;
}
.badge{
      padding: 0.9em 0.65em;
}

.addres-box.questioner-input-field {
  /* width: 55%; */
  margin: 0 auto;
}
.addres-box.questioner-input-field::after {
  border-radius: 30px;
}
.reqest-detail h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.price-per-req h5 {
  font-weight: bold;
  font-size: 36px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
.price-per-req p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #0C0C11;
}
@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 68%;
  }
}
.go-back a {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #283A5CBF;
  display: flex;
  align-items: center;
}
.go-back a > svg {
  color: #5f4fb5;
  font-size: 25px;
  margin-right: 10px;
}
.action-icon {
  font-size: 18px !important;
  cursor: pointer;
}
.action-icon:hover{
  color: #1D9BF0 !important;
}
.progress-content h4 {
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.4px;
  color: #0c0c11;
  margin-right: 70px;
  width: 200px;
}
.prpl-dashboard {
  padding: 70px 0;
}
.post-url h6 {
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  color: #0c0c11;
  padding-right: 70px;
  text-transform: none !important;
}
.fixed-w {
  width: 28%;
}
td.name-add {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase !important;
  color: #452ec6 !important;
}
/* make request details */
/* contact us */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.contact-image {
  background: url(./assets/images/twitter.jpg);
  height: 836px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login-pg .contact-image {
  height: 80vh;
}
.contact-image,
.contact-image img {
  width: 100%;
}
.contact-image > img {
  display: none;
}
.content-area {
  background: #000;
  padding: 30px;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 836px;
}
.content-area .twiter-icon > svg {
  color: #fff;
  font-size: 30px;
}
.contactus-btns .btn-white > svg {
  font-size: 30px;
}
.heading-3 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}
.login-pg .form-control {
  color: #000;
}
.login-pg .form-floating > label {
  color: #000 !important;
}
.login-pg .content-area {
  background: #fff;
  height: 80vh;
}
/* filter drop down style end */
::-webkit-scrollbar-thumb {
  background: #1D9BF0;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}
.active-tab-section {
  margin-top: 70px;
}
.custom-modal .modal-content {
  background: linear-gradient(
    270.16deg,
    #4f2c92 -26.68%,
    #1D9BF0 59.69%,
    #1D9BF0 88.22%,
    #a091f4 121.38%
  );
  border-radius: 7px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}
.custom-modal .modal-content .modal-header {
  padding: 10px 20px;
  border-color: #8978e3;
  color: #fff;
}
.custom-modal .modal-content .modal-body {
  padding: 20px;
}
.custom-modal .modal-content .modal-body .btn {
  width: 48%;
  background: #1f1845;
  padding: 15px;
}
.custom-modal .modal-content .modal-body p {
  margin-bottom: 20px;
  color: #fff;
}
.custom-modal .modal-content .modal-header .btn-close {
  filter: invert(1);
}
/* requester-petformer */
.requester-petformer {
    padding: 100px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.requester-petformer h2 {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}
.detail-section {
  padding-top: 180px;
}
.requester-petformer .item {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 50px;
    cursor: pointer;
}
.requester-petformer .item:hover {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
}
.partition {
  border-right: 2px solid #cfcdcd;
}
.detail-section h5 {
  font-size: 30px;
  color: #000;
  font-weight: bold;
}
.detail-section p {
  margin-top: 20px;
  font-size: 23px;
  width: 472px;
  text-align: center;
}
.alert-danger {
  background-color: #fc727a !important;
}

.alert-success {
  background-color: #a1e82c !important;
}
.alert-info {
  background-color: #63d8f1 !important;
}


#header.main-header .navbar {
    padding: 0;
}

#header.main-header .navbar-brand {
    padding: 0;
}

.section-content h3 {
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    color: #0C0C11;
    margin-bottom: 20px;
}

.how-it-works .section-content {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.section-content p {
    margin: 0;
}

.how-it-works {
    padding: 100px 0 0;
    position: relative;
    z-index: 9;
}

.how-it-works .item {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 50px;
    height: 100%;
}

.how-it-works .item h5 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    color: #0C0C11;
    text-transform: capitalize;
}

.how-it-works .item .icon {
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
}

.how-it-works .item .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.how-it-works .item p {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    margin-top: 30px;
}

.engaged-followers {
    padding: 550px 0 400px;
    position: relative;
    margin-top: -300px;
}
.engaged-followers::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(12 12 17 / 75%);
    z-index: -1;
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 85%);
}
.section-content .icon {
    width: 120px;
    margin-bottom: 40px;
}
.engaged-followers .section-content h3 {
    color: #fff;
}

.engaged-followers .section-content p {
    color: #FFFFFF66;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 36px;
}
.engaged-followers .section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
}
.engaged-followers .section-content .btn {
    margin-top: 20px;
}
.engaged-followers .image {
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.earn-money {
    padding-bottom: 100px;
}

.earn-money .section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.earn-money .section-content p {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 30px;
}

.earn-money .section-content .btn {
    margin-top: 20px;
}




.why-prpl {
    padding: 80px 0 120px;
    position: relative;
}

.why-prpl::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #212429;
    z-index: -1;
    clip-path: polygon(35% 0, 100% 16%, 100% 100%, 0 100%, 0 20%);
}

.why-prpl .section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-top: 50px;
}

.why-prpl .section-content h3 {
    color: #fff;
}

.why-prpl .section-content p {
    color: #FFFFFF66;
    margin-bottom: 40px;
}


.why-prpl .section-content ul {
    list-style: none;
    font-size: 22px;
    line-height: 40px;
    color: rgb(255 255 255 / 75%);
    margin-bottom: 20px;
}
.why-prpl .section-content ul li{
  margin-bottom: 10px;

}
.why-prpl .section-content a {
    font-size: 18px;
    line-height: 24px;
}
.why-prpl .image {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
}
.start-performing {
    padding: 200px 0;
    position: relative;
}
.start-performing::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F5F7FB;
    z-index: -1;
    clip-path: polygon(100% 0, 100% 80%, 60% 100%, 0 80%, 0 0);
}
.start-performing::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -2;
}

.start-performing .section-content {
    text-align: center;
    max-width: 1140px;
    margin: 0 auto;
}
.start-performing .icon {
    position: absolute;
    width: 80px;
    height: 80px;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.start-performing .icon.recycle {
    top: 50px;
    right: 40%;
}

.start-performing .icon.add-user {
    right: 15%;
    bottom: 60px;
}

.start-performing .icon.heart {
    left: 10%;
    top: 50%;
}
.action-btns-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}


.action-btns-list .btn {
    height: 60px;
    margin-right: 20px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
}

.action-btns-list .btn:last-child {
    margin: 0;
}


.footer {
    background: #fff;
}

.footer-top {
    padding: 60px 0;
}

.footer .logo {
    width: 200px;
}

.site-info p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
}

.site-info {
    max-width: 550px;
}

.footer-links h3 {
    color: #283A5C;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}

.footer-links ul {
    list-style: none;
}

.footer-links li a {
    font-size: 18px;
    line-height: 24px;
    color: #283A5CBF;
}
.footer-links li a:hover {
    color: #1D9BF0;
}

.footer-links li {
    margin-bottom: 15px;
}

.footer-bottom {
    border-top: 1px solid #283A5C1A;
    padding: 40px 0;
    text-align: center;
}

.copyrights p {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    color: #283A5CBF;
}

.bg-animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 85%);
}

.bg-animation .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.bg-animation .image img{
    width: 100%;
}
.bg-animation .frame1 {
    z-index: 5;
    opacity: 1;
    animation-name: changeimage1;
}
.bg-animation .frame2 {
    z-index: 4;
    animation-name: changeimage2;
    animation-delay: 1s;
}
.bg-animation .frame3 {
    z-index: 3;
    animation-name: changeimage3;
    animation-delay: 2s;
}
.bg-animation .frame4 {
    z-index: 2;
    animation-name: changeimage4;
    animation-delay: 3s;
}
.bg-animation .frame5 {
    z-index: 1;
    animation-name: changeimage5;
    animation-delay: 4s;
}

@keyframes changeimage1 {
  0% {
    opacity: 1;
  }

  100% {
   opacity: 0;
  }
}
@keyframes changeimage2 {
  0% {
    opacity: 1;
  }

  100% {
   opacity: 0;
  }
}
@keyframes changeimage3 {
  0% {
    opacity: 1;
  }

  100% {
   opacity: 0;
  }
}
@keyframes changeimage4 {
  0% {
    opacity: 1;
  }

  100% {
   opacity: 0;
  }
}
@keyframes changeimage5 {
  0% {
    opacity: 1;
  }

  100% {
   opacity: 0;
  }
}
.bg-gray-box {
    background: #F5F7FB;
    border-radius: 20px;
    padding: 20px;
}
.make-request-tab h3 {
    font-weight: 600;
}
.form-control.bg-blue {
    background: #1d9bf0;
}
.login-pg .btn svg {
  color: #000 !important;
}
.login-pg .btn:hover svg {
  color: #fff !important;
}
/* media quries */
@media (max-width: 1700px) {
  
  .btn-purple,
  .btn-purple:focus {
    padding: 0.5rem 22px;
    font-size: 14px;
  }
  .title-2 {
    font-size: 30px;
  }
  .reward-text h1 {
    font-size: 45px;
  }
  .reward-text h2 {
    font-size: 40px;
  }
  .make-request-tab h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .addres-box.questioner-input-field .form-control {
    line-height: 18px;
    font-size: 14px;
    min-height: calc(1.5em + 1rem + -6px);
  }
  .make-question p {
    width: 192px;
  }
  .t-url-fil {
    width: 91%;
  }
  .container-width {
    /* padding: 0px 100px; */
  }
  .contact-us {
    padding: 50px 30px 100px;
  }
  .stake-common-btn .balance-button .btn {
    width: 100%;
  }
  .usdc-field.balance-input-field {
    width: 72%;
  }
  .balance-section {
    /* padding-left: 100px; */
  }
  .banner-content h1 {
    width: auto;
    font-size: 75px;
  }
  .whyprotocol .heading {
    line-height: normal;
  }
  .list-items .intro {
    font-weight: normal;
    font-size: 25px;
    line-height: 27px;
  }
  .title {
    font-size: 40px;
  }
  p {
    /* font-size: 20px;
    line-height: 30px;
    margin: 30px 0; */
}
    .container-width {
    max-width: 1290px;
    }
    .btn-yellow {
    font-size: 18px;
    line-height: 24px;
    height: 45px;
    padding: 5px 30px;
}
    #header {
    padding: 30px 0;
    }
    .main-header .logo {
    width: 220px;
    }
    .main-header .navbar .nav-item {
    height: 45px;
    font-size: 18px;
    line-height: 26px;
    min-width: 160px;
    margin-right: 15px;
}
#site-banner {
    padding: 100px 0 80px;
}
.banner-content h1 {
        width: auto;
        font-size: 75px;
        line-height: 70px;
    }
.banner-content h2 {
    font-size: 55px;
    line-height: 68px;
}
.banner-content h4 {
    font-size: 32px;
    line-height: 30px;
}
.how-it-works {
    padding: 70px 0 0;
}
.how-it-works .section-content {
    max-width: 900px;
    margin-bottom: 60px;
}
.section-content h3 {
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 15px;
}
.how-it-works .item {
    padding: 35px;
}
.how-it-works .item .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
}
.how-it-works .item h5 {
    font-size: 26px;
    line-height: 32px;
}
.how-it-works .item p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
}
.engaged-followers {
    padding: 450px 0 300px;
    position: relative;
    margin-top: -220px;
}
.section-content .icon {
    width: 100px;
    margin-bottom: 30px;
}
.engaged-followers .section-content p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 32px;
}
.earn-money {
    padding-bottom: 70px;
}
.earn-money .section-content p {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 20px;
}
.why-prpl {
    padding: 80px 0 130px;
}
.why-prpl .section-content p {
    margin-bottom: 30px;
}
.why-prpl .section-content ul {
    font-size: 20px;
    line-height: 36px;
}
.why-prpl .section-content a {
    font-size: 16px;
    line-height: 22px;
}
.start-performing {
    padding: 150px 0;
}
.start-performing .section-content {
    max-width: 940px;
}
.action-btns-list {
    margin-top: 50px;
}
.action-btns-list .btn {
    height: 50px;
    margin-right: 15px;
    font-size: 18px;
    line-height: 22px;
}
.start-performing .icon {
    width: 60px;
    height: 60px;
}
.footer-top {
    padding: 40px 0;
}
.site-info {
    max-width: 480px;
}
.footer .logo {
    width: 160px;
}
.site-info p {
    font-size: 16px;
    line-height: 22px;
}
.footer-links h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
}
.footer-links li {
    margin-bottom: 10px;
}
.footer-links li a {
    font-size: 16px;
    line-height: 22px;
}
.footer-bottom {
    padding: 30px 0;
}
.copyrights p {
    font-size: 16px;
    line-height: 22px;

}
.active-tab-section .nav-link{
  font-size: 16px;
}
.learn-more {
    font-size: 14px;
    line-height: 18px;
}
}
@media (max-width: 1450px) {
  .questioner-input-field .form-control{
    font-size:  16px;
  }
  .container-width {
    /* padding: 0px 70px; */
  }
  .style-fields {
    padding-right: 0;
  }
  .make-question p {
    width: 214px;
    font-size: 15px;
  }
  .connect-btn,
  .wallet-btn {
    font-size: 14px;
  }
  .heading-2 {
    font-size: 24px !important;
  }
  .contactus-btns .btn-white,
  .sigunp-btn {
    width: 100%;
  }
  .contact-us {
    padding: 45px 25px 98px;
  }
  .title {
    font-size: 30px;
    line-height: 40px;
  }
  .title-2,
  .heading-2 {
    /* line-height: 0px; */
  }
  .or-text p {
    margin-left: 43%;
  }
  .whyprotocol .box-2 {
    padding: 45px 190px;
  }
  .list-items .intro {
    width: 100%;
  }
  .whyprotocol .box-1 {
    width: 100%;
  }
  .whyprotocol .box-1 {
    width: 100%;
  }
  .claim-prpl {
    width: 100%;
  }
  .stake-common-btn .balance-button .btn {
    font-size: 14px;
  }
  .staking-tab h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .staking-tab h3 {
    font-size: 30px;
  }
  .staking-tab h2 {
    font-size: 50px;
  }
  .banner-content h1 {
        font-size: 60px;
        line-height: 60px;
    }
    .banner-content h2 {
        font-size: 45px;
        line-height: 55px;
    }
    .banner-content h4 {
    font-size: 28px;
    line-height: 30px;
}
  .totall-bill-model .modal-dialog {
    padding: 0 0 !important;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin: 20px 0;
}
    .container-width {
    max-width: 1140px;
}
.btn-yellow {
    font-size: 16px;
    line-height: 22px;
    padding: 5px 20px;
}
#header {
    padding: 25px 0;
}
.main-header .logo {
    width: 180px;
}
.main-header .navbar .nav-item {
    font-size: 16px;
    line-height: 22px;
    min-width: 140px;
    margin-right: 10px;
}
#site-banner {
    padding: 90px 0 50px;
}
.how-it-works {
    padding: 50px 0 0;
}
.how-it-works .section-content {
    max-width: 800px;
    margin-bottom: 50px;
}
.section-content h3 {
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 15px;
}
.how-it-works .item {
    padding: 30px;
}
.how-it-works .item .icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
}
.how-it-works .item h5 {
    font-size: 22px;
    line-height: 30px;
}
.how-it-works .item p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
}
.engaged-followers {
    padding: 350px 0 150px;
    margin-top: -150px;
}
.engaged-followers::before {
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 90%);
}
.bg-animation {
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 90%);
}
.section-content .icon {
    width: 80px;
    margin-bottom: 20px;
}
.engaged-followers .section-content p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 28px;
}
.engaged-followers .section-content .btn {
    margin-top: 10px;
}
.earn-money {
    padding-bottom: 50px;
}
.section-content .icon {
    width: 60px;
    margin-bottom: 15px;
}
.earn-money .section-content p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 15px;
}
.earn-money .section-content .btn {
    margin-top: 10px;
}
.why-prpl {
    padding: 80px 0 100px;
}
.why-prpl .section-content p {
    margin-bottom: 20px;
}
.why-prpl .section-content ul {
    font-size: 18px;
    line-height: 34px;
}
.start-performing {
    padding: 100px 0;
}
.start-performing .icon {
    width: 50px;
    height: 50px;
}
.start-performing .icon.recycle {
    top: 30px;
}
.start-performing .icon.add-user {
    bottom: 40px;
}
.action-btns-list {
    margin-top: 40px;
}
.action-btns-list .btn {
    height: 45px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 20px;
}
.footer-top {
    padding: 30px 0;
}
.site-info {
    max-width: 420px;
}
.footer .logo {
    width: 140px;
}
.site-info p {
    font-size: 14px;
    line-height: 20px;
}
.footer-links h3 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
}
.footer-links li {
    margin-bottom: 8px;
}
.footer-links li a {
    font-size: 14px;
    line-height: 20px;
}
.footer-bottom {
    padding: 25px 0;
}
.copyrights p {
    font-size: 14px;
    line-height: 20px;
}
}
@media (max-width: 1199px) {
 
  .t-url-fil {
    width: 100%;
  }
  .inner-content {
    max-width: 100%;
    margin: 0 auto;
  }
  .questioner-input-field.custom-w {
    width: 78%;
  }
  .make-question p,
  .or-text p,
  .totall-bill-model p,
  .totall-bill-model a {
    font-size: 14px;
  }
  .post-request-text h2 {
    font-size: 16px;
  }
  .totall-bill-model .modal-content {
    padding: 30px 13px;
  }
  .style-fields {
    margin: 5px 0;
  }
  .style-fields {
    padding: 0;
  }
  .contact-image {
    background-image: none;
    height: auto;
  }
  .contact-image > img {
    display: block;
  }
  .agreement-detail p {
    width: 100%;
  }
  .or-text p {
    width: 100%;
    margin: 0;
  }
  .title-2,
  .heading-2 {
    line-height: 22px;
  }
  .whyprotocol .box-2 {
    padding: 45px 90px;
  }
  .link-second {
    margin-top: 150px;
  }
 .banner-content h1 {
        font-size: 45px;
        line-height: 50px;
    }
    .banner-content h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .banner-content h4 {
    font-size: 24px;
    line-height: 22px;
}
  .make-request-tab h2 {
    font-size: 50px;
  }
  .how-does-work h3,
  .how-does-work li,
  .learn-how {
    font-size: 17px;
  }
  .balance-section {
    /* padding-left: 0; */
  }
  .want-to-pay input {
    width: 1000%;
  }
  .usdc-field.balance-input-field {
    width: 68%;
  }
  .staking-tab h1 {
    font-size: 30px;
    line-height: 40px;
    /* width: 304px; */
  }
  .staking-tab h3 {
    font-size: 24px;
  }
  .staking-tab h2 {
    font-size: 45px;
  }
  .stake-details ol li {
    width: 100%;
    margin-right: 50px;
  }
  .price-per-req h5 {
    font-size: 22px;
    line-height: 32px;
  }
  .price-per-req p {
    font-size: 20px;
    line-height: 32px;
  }
  .make-request-tab h2 {
    font-size: 39px;
  }
  .price-per-req {
    margin-top: 20px !important;
  }
  .reward-text h1 {
    font-size: 44px;
  }
  .content-area {
    height: auto;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
}
.btn-yellow {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 20px;
}
.btn-white-border{
    font-size: 14px;
    line-height: 20px;
    padding: 5px 20px;
}
    .container-width {
    max-width: 940px;
}
    #header {
    padding: 20px 0;
}
.main-header .navbar .nav-item {
    font-size: 14px;
    line-height: 20px;
}
#site-banner {
    padding: 80px 0 40px;
}
.how-it-works {
    padding: 40px 0 0;
}
.how-it-works .section-content {
    max-width: 650px;
    margin-bottom: 40px;
}
.how-it-works .item {
    padding: 25px;
}
.how-it-works .item .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}
.how-it-works .item h5 {
    font-size: 20px;
    line-height: 28px;
}
.how-it-works .item p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
}
.engaged-followers {
    padding: 250px 0 120px;
    margin-top: -120px;
}
.section-content h3 {
    font-size: 32px;
    line-height: 40px;
}
.engaged-followers .section-content p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 26px;
}
.section-content .icon {
    width: 50px;
    margin-bottom: 10px;
}
.earn-money .section-content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
}
.earn-money {
    padding-bottom: 40px;
}
.why-prpl {
    padding: 60px 0 60px;
}
.why-prpl .section-content ul {
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 10px;
}
.start-performing .section-content {
    max-width: 750px;
}
.footer-top {
    padding: 20px 0;
}
.footer-bottom {
    padding: 20px 0;
}
.login-pg .content-area {
  height: auto;
}
.login-pg .contact-image {
  height: auto;
}
}
@media (max-width: 991px) {
  .post-url h6 {
    font-size: 16px !important;
    padding: 0;
}

.progress-content h4 {
    font-size: 18px;
    width: 100%;
    margin: 0;
}
#request {
    padding: 25px 15px 25px;
}
.active-tab-section {
	margin-top: 12px;
}
  .detail-section h5 {
    font-size: 27px;
    color: #000;
    font-weight: bold;
}
  .detail-section h5 {
    font-size: 27px;
}
  .detail-section {
    padding-top: 60px;
}
  .partition{
    border: none;
    margin-bottom: 40px;
  }
  .post-request-text h2 {
    font-size: 17px;
  }
  .request-table.tab-one-table .progress {
    width: 250px;
  }
  .request-table.tab-one-table .btn-purple {
    padding: 0.7rem 15px;
    font-size: 14px;
  }
  /* .addres-box.questioner-input-field {
    width: 100%;
    margin: 0 auto;
  } */
  .d-flex.post_url-field.questioner-input-field.flex-fill {
    width: 100%;
}
  #header.main-header .navbar-toggler {
        border: 1px solid #000;
        color: #fff;
        box-shadow: none !important;
        outline: none;
    }
    #header.main-header div#responsive-navbar-nav {
        position: absolute;
        top: 54px;
        width: 100%;
        background: #fff;
        left: 0;
    }
    .main-header .navbar-expand-lg .navbar-nav {
        justify-content: start;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
    }
    .main-header .nav-link {
        padding: 15px 1px;
        width: 100%;
    }
    .banner-content h1 {
        font-size: 57px;
    }
  .whyprotocol .space {
    margin-top: 86px;
  }
  .whyprotocol .box-2 {
    padding: 45px 43px;
  }
  .list-items .intro {
    font-size: 18px;
    line-height: 22px;
  }
  .box-2 p {
    font-size: 34px;
    line-height: 53px;
    padding: 29px 0;
  }
  .link-second {
    margin-top: 60px;
  }
  .whyprotocol {
    padding: 70px 0;
  }
  .staking-tab h1 {
    width: 100%;
  }
  .stake-details ol {
    flex-direction: column;
  }
  .stake-details ol li p {
    margin-left: 0;
  }
  .stake-details ol li {
    margin-bottom: 20px;
  }
  .claim-prpl {
    width: 100%;
    margin-bottom: 20px;
  }
  .connect-btn {
    padding: 10px 20px;
    font-size: 15px;
    margin-top: 10px;
  }
  .connect {
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .whyprotocol .box-1 {
    padding: 20px 58px;
  }
  .whyprotocol .heading {
    font-size: 45px;
  }
  .make-request-tab h2 {
    font-size: 24px;
  }
  .reward-text h1 {
    font-size: 38px;
  }
  .make-request-tab h2 {
    font-size: 20px;
  }
  .questioner-input-field .form-control{
    font-size: 15px;
  }
  .custom-design-field > p{
    font-size:17px
  }
  .container-width {
    max-width: 720px;
}
#header {
    padding: 15px 0;
    background: #fff;
    box-shadow: 0px 0px 5px 0px #00000047;
}
.main-header .navbar .nav-item {
    color: #000;
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
    justify-content: flex-start;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
}
#site-banner {
    padding: 90px 0 0px;
}
.banner-content {
    max-width: 400px;
}
.baner-image {
    max-width: 500px;
}
.how-it-works .item {
    margin-bottom: 20px;
    height: auto;
}
.bg-animation .image img {
    width: 100%;
    height: 100%;
}
.engaged-followers {
    padding: 40px 0;
    margin-top: 0;
}
.engaged-followers::before,.why-prpl::before,.start-performing::before {
    clip-path: none;
}
.bg-animation {
    clip-path: none;
}
.why-prpl {
    padding: 40px 0;
}
.why-prpl .image {
    margin-top: 40px;
}
.why-prpl .section-content{
  margin-top: 0;
}
.action-btns-list .btn{
    font-size: 14px;
}
.footer .footer-links {
    margin-top: 20px;
}
.footer-bottom {
    padding: 15px 0;
}
.price-per-req p {
    font-size: 16px;
    line-height: 30px;
}
}
@media (max-width: 767px) {
  #site-banner{
    background-image: none;
  }
  .make-request-tab .learn-more2{
    font-size: 15px;
  }
  .custom-design-field > p{
    font-size:14px
  }
  .make-request-tab h2 {
    font-size: 16px;
    line-height: 30px;
}
.reward-text h1 {
   font-size: 16px;
    line-height: 30px;
}
  #request {
    padding: 20px 10px 20px;
}
  .requester-petformer h2 {
    font-size: 24px;
}
  .partition{
    border: none;
    margin-bottom: 20px;
  }
  
  .active-tab-section {
    margin-top: 25px;
  }
  .inner-content {
    max-width: 100%;
    margin: 0 auto;
  }
  .reqest-progress {
    height: 32px;
  }
  .reqest-progress-value {
    height: 18px;
  }
  .price-per-req {
    margin-bottom: 20px;
  }
  .reqest-detail h4 {
    font-size: 20px;
  }
  .price-per-req h5 {
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
  }
  .price-per-req p {
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
  }
  [type="file"] + label,
  .btn-purple {
    padding: 0.7rem 15px;
    font-size: 14px;
  }
  .connect {
    flex-direction: column;
  }
  .whyprotocol .box-2 {
    width: 100%;
  }
  .whyprotocol .box-1 {
    padding: 20px 26px;
  }
  .social-icons li a {
    font-size: 28px;
    width: 65px;
    height: 65px;
  }
  .social-icons ul li {
    margin: 0 0 0 10px;
  }
  .more-detail a {
    font-size: 15px;
    width: 100%;
    margin-top: 70px;
    line-height: 23px;
  }
  .more-detail {
    margin-top: 20px;
  }
  .list-items .intro {
    font-size: 15px;
    line-height: 20px;
  }
  .whyprotocol .heading {
    font-size: 35px;
  }
  .banner-content h1 {
    font-size: 35px;
  }
  .figure-heading h1 {
    font-size: 34px;
  }

  .staking-tab-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
  }
  .container-width {
    /* padding: 0px 35px; */
  }
  .style-fields {
    padding: 0%;
    margin: 5px 0;
  }
  .make-question p {
    margin-right: 0;
  }
  .how-does-work h3,
  .how-does-work li,
  .learn-how {
    font-size: 15px;
  }
  .make-question p,
  .or-text p,
  .totall-bill-model p,
  .totall-bill-model a {
    font-size: 14px;
    line-height: 19px;
  }
  .progress-content h4 {
    font-size: 18px;
  }
  .prpl-dashboard {
    padding: 30px 0;
  }
  .reqest-detail .questioner-input-field .form-control{
    font-size:14px;
  }
  
}
@media (max-width: 575px) {
  .connect > button {
    max-width: 300px;
    overflow: hidden;
    margin-bottom: 20px;
}
#request {
    padding: 20px 0;
}
.active-tab-section {
    margin-top: 0;
}
  .questioner-input-field.custom-w{
    width: 100%;
  }
  .questioner-input-field .form-control{
    font-size: 14px;
  }
  .active-tab-section .nav-link {
    font-size:12px
  }
  .d-flex.go-back.back-btn-style {
    flex-direction: column;
}
.back-btn-style button {
    display: flex;
    align-items: center;
}
  .progress-content h4 {
    line-height: 10px;
    font-size: 15px;
}
  .progress-content h4 {
    line-height: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}
  .fixed-w {
    width: 100%;
}
  .detail-section p {
    padding: 10px;
    width: 100%;
    font-size:15px;
}
.requester-petformer {
  padding: 26px 0;
}
.detail-section p
  .container-width {
    /* padding: 0px 12px; */
  }
  .make-request-tab {
    padding: 20px 14px;
  }
  .t-url-fil .questioner-input-field.custom-w {
    width: 100%;
  }
  .make-request-tab {
    padding: 30px 24px;
  }
  .make-question p {
    width: 100% !important;
  }
  .container-width {
    /* padding: 0px 15px; */
  }
  /* .custom-dashboard .questioner-input-field {
    width: 100%;
  } */
  .common-w {
    width: auto;
  }
  .post-request-text h2 {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .title-2 {
    justify-content: center;
  }
  .price-per-req {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .price-per-req p {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
}
  .price-per-req h5 {
    font-size: 17px;
    line-height: 46px;
    font-weight: 500;
  }
  .price-per-req {
    margin-bottom: 6px;
}
  .reqest-detail h4 {
    font-size: 16px;
  }
  .reward-text h1 {
    font-size: 20px;
    margin-left: 20px;
  }
  .staking-tab-inner {
    align-items: center;
  }
  .connect-btn,
  .wallet-btn {
    font-size: 11px;
    padding: 8px 11px;
  }
  .title {
    font-size: 26px;
  }
  .heading-2 {
    line-height: 2px;
  }
  .heading-2 {
    font-size: 17px !important;
  }
  .contactus-btns .btn-white {
    font-size: 14px;
  }
  .contactus-btns .btn-white > svg {
    font-size: 20px;
  }
  .sigunp-btn {
    font-size: 12px;
  }
  .heading-3 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .content-area {
    height: auto;
  }
  .social-icons li a {
    font-size: 22px;
    width: 45px;
    height: 45px;
  }
  .box-2 p {
    font-size: 19px;
    line-height: 25px;
    padding: 11px 0;
  }
  .social-icons ul li {
    margin: 0 0 0 10px;
  }
  .whyprotocol .heading {
    font-size: 30px;
  }
 .banner-content h1 {
        font-size: 24px;
        line-height: 30px;
    }
    .banner-content h2 {
        font-size: 20px;
        width: 100%;
        line-height: 23px;
        margin: 0;
    }
    .banner-content h4 {
    font-size: 18px;
    line-height: 22px;
}
  .link-d {
    padding-top: 0;
    margin-bottom: 0;
  }
  .how-does-work h3,
  .how-does-work li,
  .learn-how {
    font-size: 14px;
  }
  .stake-details ol {
    margin: 30px 0 0 18px;
  }
  .make-request-btn2 {
    width: 100%;
    font-size: 20px;
  }
  .make-request-tab {
    padding: 15px;
  }
  .usdc-field.balance-input-field {
    width: 100%;
  }
  .max-button .btn {
    width: 100%;
    margin-top: 10px;
  }
  .balance-button {
    flex-direction: column;
  }
  .staking-tab h1 {
    font-size: 23px;
    line-height: 40px;
    text-align: center;
  }
  .staking-tab h3 {
    font-size: 18px;
  }
  .staking-tab h2 {
    font-size: 30px;
    line-height: 42px;
  }
  .connect-btn {
    padding: 8px 10px;
    font-size: 12px;
  }
  .btn-purple {
    font-size: 12px;
    padding: 5px;
  }
  .learn-more {
    font-size: 14px;
  }
  .text-prpl p {
    font-size: 15px;
  }
  .text-prpl span {
    font-size: 16px;
  }
  .tabone-content .card-header {
    padding: 12px;
  }
  .filters-action .dropdown-menu {
    left: 11px;
    width: 255px;
  }
  .option-values [type="radio"]:checked + label,
  .option-values [type="radio"]:not(:checked) + label {
    width: 50px;
  }
  .filters-action .dropdown-menu label {
    font-size: 12px;
  }
  .angle-icon:first-child {
    margin-left: 10px;
  }
  .angle-icon {
    margin-left: 20px;
  }
  .active-tab-section .card-footer {
    padding: 10px;
  }
  .main-header .logo {
    width: 150px;
}
#header.main-header .navbar-toggler {
    padding: 3px 5px;
}
.navbar-light .navbar-toggler-icon{
    width: 25px;
    height: 25px;
}
p {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
}
.how-it-works {
    padding: 30px 0 0;
}
.how-it-works .section-content{
    margin-bottom: 20px;
}
.section-content h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
}
.how-it-works .item {
    padding: 15px;
    border-radius: 10px;
}
.engaged-followers {
    padding: 20px 0;
}
.why-prpl {
    padding: 20px 0;
}
.why-prpl .section-content p {
    margin-bottom: 10px;
}
.why-prpl .section-content ul {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
}
.why-prpl .section-content a {
    font-size: 14px;
    line-height: 20px;
}
.start-performing .icon{
    display: none;
}
.start-performing {
    padding: 30px 0;
}
.action-btns-list {
    margin-top: 20px;
}
.footer-links h3{
    margin-bottom: 10px;
}
.footer-links li {
    margin-bottom: 5px;
}
.copyrights p {
    font-size: 12px;
}
}
@media (max-width: 320px) {

.action-btns-list {
    flex-direction: column;
}
.action-btns-list .btn {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}